::selection {
    background: yellow;
}

a, a:visited {
    color: inherit;

    &:hover { background: yellow }
}

.underline { border-bottom: 1px solid; }

.highlight {
    text-decoration: none;
    padding-right: 0.25em;
    padding-left: 0.25em;

    &:hover { background: yellow }
}

html,
body {
    font: 16px/1.68 'Karla', sans-serif;
}

body {
    margin: 3rem 1rem 1rem;
}

img,
svg {
    max-width: 100%;
    height: auto;
}

main {
    @extend .wrap;
    
    padding-bottom: 4em;
}

h1, h2, h3 {
    ::selection {
        background: rgb(0, 255, 255);
    }
}

h1 {
    font-size: 2.5em;
    line-height: 1;
    margin: 0 0 0.25em 0;
    text-transform: uppercase;
}

h3 {
    font-size: 1em;
    @media screen and (min-width: 640px) {
        font-size: 1.17em;
    }
}

h5 {
    font-size: 1em;
    font-weight: 700;
    text-decoration: underline;
    margin-bottom: 0.5em;
}

p { margin: 0 0 0.6em; }


.small {
    font-size: 0.8em;
    line-height: 1.6;
    display: inline-block;
}

.er {
    font-size: 0.6em;
}

.wrap {
    max-width: 52rem;
    margin: 0 auto;
}

.head-room {
    margin-top: 3rem;
}

.banner {
    text-align: center;
    
    p {
        font-size: 0.8em;
        
        @media screen and (min-width: 30em) {
            font-size: 1em;
        }
    }
}

.center { text-align: center; }
.right { text-align: right; }
.left { text-align: left; }

.wave {
    display: inline-block;
    animation: wave 5s infinite;
}

.clean {
    text-decoration: none;
}

@keyframes wave {
    8% { transform: rotate(10deg) }
    12% { transform: rotate(-40deg) }
    15% { transform: rotate(10deg) }
    19% { transform: rotate(-50deg) }
    25% { transform: rotate(10deg) }
    34% { transform: rotate(0deg) }
}

.client {
    margin-top: 4em;
}

.video {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    margin: 1rem;

    > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}