.things {
    @extend .clean;

    margin: 2rem 0;

    h3 { border-top: 1px solid; }

    h3:hover span {
        background: rgb(0, 255, 255);
    }

    & .expanded > h3 span {
        background: rgb(0, 255, 255);
    }

    & >li:last-of-type { border-bottom: 1px solid; }

    .expands {
        padding: 0 0 0 0;
    }

    &.tight .expands {
        max-width: 30em;
        margin: 0 auto;
    }

    & & {
        margin-top: 1.5rem;

        h3 {
            border-top: 1px solid rgba(black, 0.1);

            &:after {
                opacity: 0.4;
            }
        }

        li:last-of-type { border-bottom: 1px solid rgba(black, 0.1); }
    }
}

.expands {

    h3 {
        position: relative;
        line-height: 1;
        padding: 1em 0;
        margin: 0;

        &:hover {
            cursor: pointer;
        }
        
        &:after {
            content: "+";
            position: absolute;
            right: 0.5em;

            transition: transform 250ms;
        }
    }

    &.expanded > h3:after { transform: rotate(135deg); }

    .right & h3:after {
        left: 0.5em;
        right: auto;
    }
}

.expands {
    
    &.expanded > .collapsed { display: block; }
}

.collapsed {
    display: none;
    margin: 0;
    text-align: left;
    padding-bottom: 2em;
}

.slider {
    position: relative;

    &__slides {
        @extend .clean;

        margin: 2em 0.5em 0;
        white-space: nowrap;
        overflow: hidden;
    }

    &__slide {
        display: inline-block;

        img { display: block; }
    }

    &__arrow {
        @extend %reset;

        position: absolute;
        z-index: 1;
        top: 50%;
        width: 10%;
        height: 100%;

        transform: translateY(-50%);

        svg {
            width: 1em;
            fill: rgba(black, 0.2);

            transition: fill 250ms;
        }

        &:hover svg {
            fill: rgba(black, 0.5)
        }

        &:first-of-type {
            left: -1em;
            text-align: left;

            &:hover { cursor: w-resize; }
            
            svg { transform: rotate(180deg); }
        }
        
        &:last-of-type {
            &:hover { cursor: e-resize; }
            text-align: right;
            right: -1em;
        } 
    }
}

.logos {
    @extend .clean;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    li {
        margin: 2em 0.5em;
        max-width: calc(33.333% - 1em);
        
        @media screen and (min-width: 640px) {
            margin: 2em 1.5em;
            max-width: calc(33.333% - 3em);
        }
    }

    img {
        filter: saturate(0);
        
        &:hover { filter: saturate(1); }
    }
}