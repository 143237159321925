.navigation {
    @extend .clean;
    @extend .wrap;

    display: flex;
    justify-content: center;
    font-size: 1.5em;
    font-weight: 700;
    margin: 2rem auto;
    
    @media screen and (min-width: 30em) {
        
        font-size: 2em;
        margin: 3.5rem auto;
    }

    li {
        opacity: 0.2;
        transition: opacity 200ms;

        &:hover,
        &.active { opacity: 1; }

        &:not(:last-of-type) {
            margin-right: 1rem;

            @media screen and (min-width: 30em) {
                margin-right: 1.8rem;
            }
        }
    }

    a {
        text-decoration: none;
    }
}