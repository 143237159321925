.clean {
    list-style: none;
    padding-left: 0;
    margin-left: 0;
    margin-right: 0;
}

%reset {
    -webkit-appearance: none;
    border: none;
    background: none;

    &:focus { outline: none; }
}